<template>
  <div id="view-associates">
        <div class="position-relative revealer">
            <div class="container">
                <div class="row section-header-height">
                    <div class="col-lg-12 align-self-center aos-init aos-animate" data-aos="fade-in">
                        <h6 id="in" class="page-title">{{ $t('texto119') }} <br> {{ $t('texto120') }} </h6>
                        <h2>{{ $t('texto121') }} </h2>
                        <p>
                            {{ $t('texto122') }} 
                        </p>
                    </div>
                    
                    <div class="col-lg-12 align-self-center order-0 order-lg-1 aos-init aos-animate" data-aos="fade-in">
                        <h4 class="font-weight-bold">{{ $t('texto123') }} </h4>
                        <p>
                            {{ $t('texto124') }} 
                        </p>
                        <img :src="require('@/assets/images/solutions/university.svg')" class="img-fluid mt-4" width="150">
                    </div>
                </div>
            </div>

            
        </div>

        

        <!-- <div class="container">
            <div class="row section-full-height">
                <div class="col-lg-6 align-self-center aos-init aos-animate" data-aos="fade-in">
                    <h4 class="font-weight-bold">Comunidad</h4>
                    <h5>Juntos somos mejores</h5>
                    <p>
                        En ACTO buscamos desarrollar e incrementar el potencial de nuestros asociados, provocando que traspasen sus límites al hacer equipo. Si como nosotros buscas mejorar la vida de las personas, únete a ACTO.
                    </p>

                    <p class="pl-4">
                        <strong>Consultoría y ventas</strong> <br> Si buscas potencias tu capacidad y la de tu equipo, podemos ayudar en el diseño e implementación de estrategias de ventas para prospección y cierre.
                    </p>

                    <p class="pl-4">
                        <strong>Agentes</strong> <br> Se parte de la nueva era de agentes, nuestro programa te permite maximizar tus ganancias a la industria de seguros, acude a nuestros centros educativos o aprende en línea y aumenta tus ventas como
                        agente persona física o agente persona moral.
                    </p>


                </div>

                <div class="col-lg-6 col-xl-5 offset-xl-1 align-self-center aos-init aos-animate" data-aos="fade-in">
                    <h2>Quiero formar parte de la comunidad</h2>
                    <hr>
                    <p>
                        Ingresa con tu red social
                    </p>

                    <a href="https://facebook.com" target="_blank"><img :src="require('@/assets/images/fb-login.png')" class="img-fluid mb-3"></a>

                    <a href="https://google.com" target="_blank"><img :src="require('@/assets/images/google-login.png')" class="img-fluid mb-3"></a>

                    <a href="https://linkedin.com" target="_blank"><img :src="require('@/assets/images/linkedin-login.png')" class="img-fluid mb-3"></a>

                    <p>
                        O escríbenos un mensaje
                    </p>
                    <form action="/soluciones-asociados" data-ajax="true" data-ajax-begin="onBegin" data-ajax-method="POST" data-ajax-success="onSuccess" id="form0" method="post" novalidate="novalidate">
                        <div class="form-group">
                            <input class="form-control" data-val="true" data-val-required="El nombre es requerido" id="Name" name="Name" placeholder="Nombre" type="text" value="">
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <input class="form-control" id="Age" name="Age" placeholder="Edad" type="text" value="">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <select class="form-control" id="Gender" name="Gender"><option value="">Sexo</option>
<option value="M">Hombre</option>
<option value="F">Mujer</option>
</select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <input class="form-control" data-val="true" data-val-required="El teléfono es requerido" id="Phone" name="Phone" placeholder="Teléfono" type="text" value="">
                        </div>
                        <div class="form-group">
                            <input class="form-control" data-val="true" data-val-required="El correo es requerido" id="Email" name="Email" placeholder="Correo" type="text" value="">
                        </div>
                        <div class="form-group">
                            <div class="custom-control custom-checkbox">
                                <input class="custom-control-input" data-val="true" data-val-required="The Aggrement field is required." id="Aggrement" name="Aggrement" type="checkbox" value="true"><input name="Aggrement" type="hidden" value="false">
                                <label class="custom-control-label" for="Aggrement">
                                He leído y estoy de acuerdo con la <a href="#">Política de Privacidad</a>
                            </label>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary">Enviar</button>
                    </form>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
export default {

}
</script>

<style>
#in{
    color: #5ABA56;
}
</style>