<template>
<div id="view-insurers">
    <div class="position-relative revealer">
        <div class="container">
            <div class="row section-header-height">
                <div class="col-lg-12 align-self-center" data-aos="fade-in">
                    <h6 id="in" class="page-title">{{ $t('soluciones') }}<br /> {{ $t('aseguradoras') }}</h6>
                    <h2>{{ $t('texto4') }}</h2>
                    <p>
                       {{ $t('texto5') }}
                    </p>
                    <br>
                <br>
                <br>
                </div>
                <br>
                <br>
                <br>
                <div class="col-lg-12 align-self-center" data-aos="fade-in">
                    <h4 class="font-weight-bold">Partners</h4>
                    <p>
                        {{ $t('texto6') }}
                    </p>
                    <br>
                <br>
                <br>
                </div>

            </div>
        </div>

    </div>

    <div class="position-relative revealer">
        <div class="container">
            <div class="row section-full-height">
                <div class="col-lg-12 align-self-center" data-aos="fade-in">
                    <h4 class="font-weight-bold">{{ $t('texto7') }}</h4>
                    <p>
                        {{ $t('texto8') }}
                    </p>
<br>
                <br>
<br>
                    <p class="pl-4">
                        <strong>Software ( SaaS )</strong> <br /> {{ $t('texto9') }}
                    </p>
                    <br>
                <br>

                    <p class="pl-4">
                        <strong>API´s</strong> <br /> {{ $t('texto10') }}
                    </p>
<br>
                <br>

                    <p class="pl-4">
                        <strong>{{ $t('texto11') }} e-commerce B2B</strong> <br /> {{ $t('texto12') }}
                    </p>
<br>
                <br>

                    <p class="pl-4">
                        <strong>Mobile B2B</strong> <br /> {{ $t('texto13') }}
                    </p>
<br>
                <br>

                    <p class="pl-4">
                        <strong>CRM B2B</strong> <br /> {{ $t('texto14') }}
                    </p>
                    <br>
                <br>
<br>
                <br>

                </div>
                <div class="col-lg-12 align-self-center aos-init aos-animate" data-aos="fade-in">
                            <h4 class="font-weight-bold">Data Insights</h4>
                            <p>
                               {{ $t('texto15') }}
                            </p>
                            
                        </div>
            </div>
            
                        <br>
                        <br>
                            <br>
        </div>
    </div>

    <!--<div class="position-relative">
        <div class="container-fluid">
            <div class="row section-full-height">
                <div class="container">
                    <div class="row section-full-height">
                        <div class="col-lg-12 align-self-center aos-init aos-animate" data-aos="fade-in">
                            <h4 class="font-weight-bold">Data Insights</h4>
                            <p>
                                Hoy más que nunca la información es el activo más valioso y somos muy
                                consientes de eso. Gracias a nuestros diferentes modelos de negocio,
                                podemos obtener información limpia, organizada y lista para capitalizar.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

         <div class="animation-container overflow-hidden">
            <div class="container-fluid">
                <div class="row section-full-height insights-bg">
                </div>
            </div>
        </div> 
    </div>-->
    <div class="position-relative overflow-hidden">
        <div class="animation-container" data-aos="fade-left">
            <div class="container-fluid">
                <div class="row section-full-height insurtech-bg">
                    <div class="col-lg-12 align-self-center" data-aos="fade-down">
                        <h4 class="font-weight-bold">Insurtech</h4>
                        <p>
                            {{ $t('texto16') }} 
                        </p>

                        <p>
                            {{ $t('texto17') }} 
                        </p>

                        <p>
                             {{ $t('texto18') }} 
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
// Lo declaramos globalmente

export default {
    name: "Membresias",
    components: {

    },
    mounted() {

    }
}
</script>

<style>
#in {
    color: #5ABA56;
}

.aboutus-video-center {
    position: absolute;
    top: 10;
    left: 10;
    bottom: 0;
    right: 0;
    width: 100%;
}

@media (max-width: 767px) {
    .aboutus-video-center {
        width: 100%;
        height: 100%;
        top: 0;
    }

}

.aboutus-video-right {
    position: absolute;
    top: 10;
    left: 10;
    bottom: 0;
    right: 0;
    width: 100%;
}

@media (max-width: 767px) {
    .aboutus-video-right {
        height: 100%;
        top: 11%;
    }

}

.aboutus-video-left {
    position: absolute;
    top: 10;
    left: 10;
    bottom: 0;
    right: 0;
    width: 100%;
}

@media (max-width: 767px) {
    .aboutus-video-left {
        align-content: flex-end;
        padding-top: 99.9%;
        padding-left: 10%;
        padding-right: -30%;
        width: 100%;
        height: 100%;
        top: 0;
    }
}

video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}
</style>
