<template>
<div class="about">
    <div id="view-solutions" class="position-relative">
        <div class="container">
            <div class="row section-header-height">
                <div class="col-lg-12 align-self-center">
                    <h6 id="in" class="page-title">SOLUCIONES</h6>
                    <h2>Somos un equipo con amplio conocimiento de las industrias Insurtech y Fintech</h2>
                    <p>
                        Estamos unidos por la pasión de facilitar el complejo negocio de los
                        seguros, las asistencias y las finanzas a través de la innovación y
                        tecnología intuitiva.
                    </p>
                    <a class="btn btn-primary mb-3">
                        <router-link to="/contacto">Contáctanos</router-link>
                    </a>
                </div>
            </div>
            <div class="row section-header-height">
                <div class="col-lg-12 align-self-center">
                    <div class="col-lg-12">
                        <h3>
                            No trates de definirnos, no es sencillo.
                        </h3>
                    </div>
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <p>
                                    Queremos ayudar a todas las cadenas de suministro y servicio que
                                    integran a las industrias financieras, seguros y asistencias a lograr
                                    mejores resultados y al mismo tiempo cambiar la manera en que
                                    consumimos estos productos y servicios.
                                </p>
                            </div>
                            <div class="col">
                                <p>
                                    Creemos que el mundo merece una mejor experiencia y nosotros
                                    estamos aquí para apoyar a todo aquel que sea parte de la industria y
                                    quiera mejorar sus practicas.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="row section-header-height">
                    <div class="col-lg-6 align-self-center">
                        <p>
                            ACTO encuentra y llena esos espacios que no permiten a estas industrias
                            maximizar sus ingresos y mejorar procesos. Nosotros somos el frente y el
                            fondo, encontramos el problema y ofrecemos las soluciones.
                        </p>
                        <h4>
                            Nosotros somos el frente y el fondo, encontramos el problema y ofrecemos las soluciones
                        </h4>
                        <p class="mb-5">
                            Apóyate de ACTO y conquista tu futuro.
                        </p>

                        <a class="btn btn-primary mb-3">
                            <router-link to="/contacto">Contáctanos</router-link>
                        </a>
                    </div>
                    <div class="col-lg-6 align-self-center order-1 order-lg-0">
                        <lottie-animation ref="anim" :animationData="require('@/assets/images/animations/soluciones.json')" :loop="true" />
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'About',
    components: {

    },
    mounted() {

    },
    methods: {

    }
}
</script>

<style>

</style>
