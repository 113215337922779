<template>
  <div id="view-about">
    <div class="position-relative about-section">
      <div class="container">
        <div class="row section-header-height text-center">
          <div class="col align-self-center" data-aos="fade-in">
            <div class="row mt-5">
              <!--<div class="col-lg-6 offset-lg-3">
                            <img id="gif" :src="require('@/assets/images/nosotros/cubo1.svg')"> 
							
						</div>-->
              <h4 class="my-6 about-section">
                {{ $t("texto20") }}
              </h4>
            </div>

            <div class="row">
              <div class="col-lg-6 offset-lg-3">
                <h5>
                  {{ $t("texto21") }}
                </h5>
              </div>
            </div>
            <br />
            <br />
          </div>
          <div class="row section-full-height" data-aos="fade-down"></div>
        </div>
      </div>
    </div>

    <div class="position-relative overflow-hidden about-section" >
      <div class="animation-container overflow-hidden" data-aos="fade-left">
        <div class="container-fluid">
          <div class="row section-full-height">
            <div class="row">
              <div class="col-lg-8 offset-lg-2 align-self-center text-center">
                <h5 class="mb-4">
                  {{ $t("texto22") }}
                </h5>

                <h4 class="pb-5">
                  {{ $t("texto23") }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>

    <div
      id="trayectoria"
      class="position-relative about-section"
      style="z-indez: 3, color: "
    >
      <div class="container" style="z-indez: 3">
        <div
          class="row section-full-height"
          data-aos="fade-in"
          style="z-indez: 3"
        >
          <div class="col-lg-12 align-self-center" style="z-indez: 3;">
            <h2 class="mb-4" >{{ $t("texto24") }}</h2>
            <h4 class="mb-4" >
              {{ $t("texto25") }}
            </h4>
            <h5 class="mb-4" >
              {{ $t("texto26") }}
            </h5>
            <h6 id="in" class="page-title">
              {{ $t("texto27") }}
            </h6>
            <br />
            <br />
            <br />
          </div>
          <div class="col-lg-12 align-self-center" data-aos="fade-in">
            <h2 class="mb-4">{{ $t("texto28") }}</h2>
            <h5 class="mb-4" >
              {{ $t("texto29") }}
            </h5>
            <br />
            <br />
            <br />
          </div>

          <div class="col-lg-6 col-xl-5 offset-xl-1 align-self-center">
            <!-- <img id="gif" :src="require('@/assets/images/soluciones/aseguradoras/cubo2.svg')"> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuienesSomos",
  components: {},
};
</script>

<style>
#in {
  color: #5aba56;
}

@media (max-width: 999px) {
  section {
    z-index: 1;
    position: relative;
  }

  #aboutus-video {
    display: none;
  }

  #aboutus-video-mobile {
    padding-top: 400px;
    /*position: relative;*/
    width: 100%;
    height: auto;
  }
  #aboutus-video-mobile-2 {
    padding-top: 400px;
    /*position: relative;*/
    width: 100%;
    height: auto;
  }
  #aboutus-video-mobile-3 {
    padding-top: 400px;
    /*position: relative;*/
    width: 100%;
    height: auto;
    padding-bottom: 580px;
  }
}

@media (min-width: 1000px) {
  #aboutus-video-mobile-2 {
    display: none;
  }
  #aboutus-video-mobile-2 {
    display: none;
  }
  #aboutus-video-mobile-3 {
    display: none;
  }

  #aboutus-video {
    display: block;
  }
}

.aboutus-video-center {
  position: absolute;
  top: 10;
  left: 10;
  bottom: 0;
  right: 0;
  width: 100%;
}

@media (max-width: 767px) {
  .aboutus-video-center {
    width: 100%;
    height: 100%;
    top: 0;
  }
}

.aboutus-video-right {
  position: absolute;
  top: 10;
  left: 10;
  bottom: 0;
  right: 0;
  width: 100%;
}

@media (max-width: 767px) {
  .aboutus-video-right {
    align-content: flex-end;
    padding-top: 99.9%;
    padding-right: 10%;
    padding-left: -10%;
    width: 100%;
    height: 100%;
    top: 0;
  }
}

.aboutus-video-left {
  position: absolute;
  top: 10;
  left: 10;
  bottom: 0;
  right: 0;
  width: 100%;
}

@media (max-width: 767px) {
  .aboutus-video-left {
    align-content: flex-end;
    padding-top: 99.9%;
    padding-left: 20%;
    padding-right: -30%;
    width: 100%;
    height: 100%;
    top: 0;
  }
}

video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.about-section {
  z-index: 1;
  position: relative;
}

/* 
.fade-out {
	-webkit-animation: fade-out 1s ease-out both;
	animation: fade-out 1s ease-out both;
}


----------------------------------------------
 * Generated by Animista on 2021-4-1 4:8:43
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-out
 * ----------------------------------------

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
} */
</style>