import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Seguros from "../views/Seguros.vue";
import Membresias from "../views/Membresias.vue";
import QuienesSomos from "../views/QuienesSomos.vue";
import Soluciones from "../views/Soluciones.vue";
import Fianzas from "../views/Fianzas.vue";
import Blog from "../views/Blog.vue";
import Login from "../views/Login.vue";
// import Contacto from "../views/Contacto.vue";
import Aseguradoras from "../views/Aseguradoras.vue";
import Asociados from "../views/Asociados.vue";
import Empresas from "../views/Empresas.vue";
import About from "../views/About.vue";
import Success from "../views/Success.vue";
import FAQPage from "../views/FAQ.vue";
import Privacy from "../views/Privacy.vue";
import Demo from "../views/Demo.vue";
//import i18n from '@/i18n';
import Meta from 'vue-meta'
//for errors
import Error from "../views/404.vue";


Vue.use(Meta);
Vue.use(VueRouter);

const routes = [{

        path: "/",
        name: "Home",
        component: Home

    },
    {
        path: "/seguros",
        name: "Seguros",
        component: Seguros
    },
    {
        path: "/membresias",
        name: "Membresias",
        component: Membresias
    },
    {
        path: "/quienes-somos",
        name: "QuienesSomos",
        component: QuienesSomos
    },
    {
        path: "/soluciones",
        name: "Soluciones",
        component: Soluciones
    },
    {
        path: "/fianzas",
        name: "Fianzas",
        component: Fianzas
    },
    {
        path: "/blog",
        name: "Blog",
        component: Blog
    },
    {
        path: "/login",
        name: "Login",
        component: Login
    },
    {
        path: "/contacto",
        name: "Contacto",
        component: Demo
    },
    {
        path: "/demo",
        name: "Demo",
        component: Demo
    },
    ////////////////////////////////////////soluciones///////////////////////////////////////////////////
    {
        path: "/soluciones/aseguradoras",
        name: "Aseguradoras",
        component: Aseguradoras
    },
    {
        path: "/soluciones/asociados",
        name: "Asociados",
        component: Asociados
    },
    {
        path: "/soluciones/empresas",
        name: "Empresas",
        component: Empresas
    },
    ////////////////////////////////////////end///////////////////////////////////////////////////
    {
        path: "/about",
        name: "About",
        component: About
    },
    {
        path: "*",
        name: "Error",
        component: Error
    },
    {
        path: "/success",
        name: "Success",
        component: Success
    },
    {
        path: "/faq",
        name: "FAQ",
        component: FAQPage
    },
    {
        path: "/privacy",
        name: "Privacy",
        component: Privacy
    }


];


const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        //scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }

    }

});

export default router;