<template>
<div id="view-solutions" class="position-relative">
        <div class="container">
            <div class="row section-header-height">
                <div class="col-lg-12 align-self-center">
                    <h6 id="in" class="page-title">{{ $t('soluciones') }}</h6>
                    <h2>{{ $t('texto31') }}</h2>
                    <p>
                        {{ $t('texto32') }}
                    </p>
                    <a class="btn btn-primary mb-3">
                        <router-link to="/contacto">{{ $t('texto33') }}</router-link>
                    </a>
                    <br>
                    <br>
                    <br>
                    <br>
                    <div class="col-lg-12">
                        <h3>
                            {{ $t('texto34') }}
                        </h3>
                    </div>
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <p>
                                    {{ $t('texto35') }}
                                   
                                </p>
                                <p>
                                    {{ $t('texto36') }}
                                    
                                </p>
                        <h4>
                            {{ $t('texto37') }}
                        </h4>
                        <p class="mb-5">
                            {{ $t('texto38') }}
                        </p>

                        <a class="btn btn-primary mb-3">
                            <router-link to="/contacto">{{ $t('texto33') }}</router-link>
                        </a>
                            </div>
                            
                        </div>
                </div>
            </div>
            <div class="row section-header-height">
                <div class="col-lg-12 align-self-center">
                    

                    </div>
                </div>
                <div class="row section-header-height">
                    <div class="col-lg-12 align-self-center">
                        
                    </div>
                </div>

            </div>
        </div>
        <br>
        <br>
    </div>
</template>

<script>
export default {
    name: "Soluciones",
    components: {

    }

}
</script>

<style>
#in{
    color: #5ABA56;
}

@media (max-width: 999px) {
  section {
    z-index: 1;
    position: relative;}
    
    #aboutus-video{
        display: none;
        
  
    }

    #aboutus-video-mobile{
        padding-top: 400px;
        /*position: relative;*/
        width: 100%;
        height: auto;
  
    }

    
    

}


</style>