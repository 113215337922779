<template>           
<div id="view-solutions">
    <div class="header container-fluid">
        <div class="container">
            <div class="row text-center text-blue">
                <div class="col-lg-12 align-self-center">
                    <br>
                    <br>
                    <br>
                <h2>{{ $t('texto145') }}</h2>
            </div>
            </div>
        </div>
    </div>

<div class="container-fluid" style="background-color: white;">
    <div class="container">
        <div class="row">
            <div class="col-12 py-5">
                <input class="form-control form-dark" id="search" name="search" placeholder="Haga una búsqueda" type="text" value="">
            </div>
            <div class="row py-5">
                <div class="col-lg-4">
                    <ul class="list-unstyled faq-categories">
                        <li>
                            <a href="#" class="h5 font-weight-bold">{{ $t('texto145') }}</a>
                        </li>
                        <li>
                            <a href="#" class="h5 font-weight-bold">{{ $t('texto147') }}</a>
                        </li>
                        <li>
                            <a href="#" class="h5 font-weight-bold">{{ $t('texto148') }}</a>
                        </li>
                        <li>
                            <a href="#" class="h5 font-weight-bold">{{ $t('texto149') }}</a>
                        </li>
                        <li>
                            <a href="#" class="h5 font-weight-bold">{{ $t('texto150') }}</a>
                        </li>
                        <li>
                            <a href="#" class="h5 font-weight-bold">{{ $t('texto151') }}</a>
                        </li>
                        <li>
                            <a href="#" class="h5 font-weight-bold">{{ $t('texto152') }}</a>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-8 align-self-center">
                    <h5 class="blue">
                    {{ $t('texto153') }}
                </h5>
                <p class="p">
                    {{ $t('texto154') }}
                </p>
                <hr class="my-5" style="border-color: rgba(0, 38, 77, 0.2);">
                <h5 class="blue">
                    {{ $t('texto155') }}
                </h5>
                <p class="p">
                    {{ $t('texto156') }}
                </p>
                <hr class="my-5" style="border-color: rgba(0, 38, 77, 0.2);">
                <h5 class="blue">
                    {{ $t('texto157') }}
                </h5>
                <p class="p">
                    {{ $t('texto158') }}
                </p>
                <hr class="my-5" style="border-color: rgba(0, 38, 77, 0.2);">
                <h5 class="blue">
                    {{ $t('texto159') }}
                </h5>
                <p class="p">
                    {{ $t('texto160') }}
                </p>
                <hr class="my-5" style="border-color: rgba(0, 38, 77, 0.2);">
                <h5 class="blue">
                    {{ $t('texto161') }}
                </h5>
                <p class="p">
                    {{ $t('texto162') }}
                </p>
                <hr class="my-5" style="border-color: rgba(0, 38, 77, 0.2);">
                <h5 class="blue">
                    {{ $t('texto163') }}
                </h5>
                <p class="p">
                    SaaS, API, venta e-commerce B2B, App Mobile B2C, App Web B2C y App Web B2B.
                </p>
                    <hr class="my-5" style="border-color: rgba(0, 38, 77, 0.2);">

                    <p class="p">{{ $t('texto164') }}</p>
                    <ul class="list-unstyled p">
                        <li class="pl-5">{{ $t('texto165') }}</li>
                        <li class="pl-5">{{ $t('texto166') }}</li>
                        <li class="pl-5">
                            {{ $t('texto167') }}
                        </li>
                        <li class="pl-5">{{ $t('texto168') }}</li>
                        <li class="pl-5">{{ $t('texto169') }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid" style="background-color: #eee;">
    <div class="container">
        <div class="row text-center text-blue">
            <div class="col py-5">
                <h5 class="blue">
                    {{ $t('texto170') }}
                </h5>
            </div>
            <div class="col py-5 text-center">
                <p class="m-0 p">{{ $t('texto171') }}</p>
                <h5 class="blue">
                    +52 33 1410 1269
                </h5>
            </div>
            <div class="col py-5 text-center">
                <p class="m-0 p">{{ $t('texto172') }}</p>
                <h5 class="blue">
                    ventas@grupoacto.com
                </h5>
            </div>
        </div>
    </div>
</div>
</div>
</template>

<script>
export default {

}
</script>

<style>
.p{
    color: #00264d;
}

.blue{
    font-weight: bold;
    color: #00264d;
}
</style>