<template>           
<VueDocPreview value="https://drive.google.com/file/d/1fS0KZtdlf4tN-MLQatiyf9-ZnT62r5WG/view?usp=sharing" type="office" />
</template>

<script>
import VueDocPreview from 'vue-doc-preview'
export default {
    components: {
    VueDocPreview
  }

}
</script>

<style>


</style>