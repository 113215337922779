import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    'en': {
        soluciones: "Solutions",
        empresas: "For Enterprises",
        aseguradoras: "For Insurers",
        asociados: "For Associates",
        nosotros: "About Us",
        membresias: "Memberships",
        seguros: "Insurances",
        salud: "Health",
        vida: "Life",
        hogar: "Home",
        empresa: "Enterprise",
        auto: "Car",
        motocicleta: "Motocycle",
        viaje: "Travel",
        mascota: "Mascot",
        fianzas: "Bail",
        blog: "Blog",
        login: "Login",
        contacto: "Contact",
        idioma: "Language",
        header1: "Every",
        header2: "great change",
        header3: "starts with a",
        text: {
            link:{
                web: "Go to web site",
            }
        },
        brands: "Brands",
        btn: {
            header: "Show me more"
        },
        texto1: "Every great change begins with an",
        texto2: "Our purpouse is to increase your life quality trough financial freedom with an easy and accesible way.",
        texto3: "We connect people and enterprises with InsurTech & FinTech industries trough creative solutions and tecnology",
        copyright: "ALL RIGHTS RESERVED",
        correo: "E-mail contact",
        phone1: "Contact phone",
        phone2: "Call center phone",
        productos: "PRODUCTS",
        entera: "FULL INSURANCE",
        devolucion: "INSURANCE WITH REFUND",
        jubilacion: "RETIREMENT INSURANCE",
        universidad: "UNIVERSITY INSURANCE",
        membresias_footer: "ASISTANCES AND INSURANCES",
        trayectoria: "OUR PATH",
        que_hacemos: "WHAT WE DO",
        clientes: "OUR CLIENTS",
        privacidad: "PRIVACY NOTICE",
        app: "DOWNLOAD THE APP",
        texto4:"We have more than 30 years of experience which allows us to understand the new challenges.",
        texto5:"We know that social and technological changes have generated greater demands in time, service and efficiency, so we have designed tools that allow us to be faster in the processes, more efficient in communication and more human in service.",
        texto6:"Let's team up, we can help you improve processes, understand new market niches and the way to reach them, evolve your business model and establish comprehensive strategies that reduce the time to obtain results with a long-term vision.",
        texto7:"Technological solutions",
        texto8:"Insurtech is defined as the use of technological tools that have evolved the way of generating coverage and opening new markets in the insurance world. In ACTO we have a team that designs disruptive tools that allow greater penetration and management of market information.",
        texto9:"Use of our e-commerce system, customer service and fraud prediction system for the distribution and service of products digitally.",
        texto10:"APIs allow your products and services to communicate with others, without the need to know how they are implemented. This simplifies application development and saves time and money on integrations.",
        texto11:"Sales ",
        texto12:"We help clients integrate the insurance industry into their e-commerce in a variety of ways.",
        texto13:"Use of mobile app platform to encourage purchase or communicate in an efficient, effective and timely manner.",
        texto14:"We use a CRM designed to improve communication with the end user. We provide efficiency through automated responses and friendliness through personalized and humanized attention.",
        texto15:"Today more than ever, information is the most valuable asset, and we are very aware of that. Thanks to our different business models, we can obtain clean, organized and ready to capitalize information.",
        texto16:"This industry allows us to look forward to a brighter future in terms of insurance and quality of life.",
        texto17:"ACTO's mission is to become one of the most important companies in the world in this industry.",
        texto18:"The possibilities are limitless, we understand that innovation supported by the structure of insurance companies will make this industry the next big wave.",
        texto19:"US",
        texto20:"We are a multidisciplinary team of experts in Insurtech that designs and develops adaptable, accessible and scalable technological solutions.",
        texto21:"We are motivated by the development of solutions for problems that we know in depth.",
        texto22:"Because we know that every ACTO that improves the life of a person, will become a trend that multiplied by thousands, will improve the quality of life of a society.",
        texto23:"At ACTO we are committed to innovation as the way to transform and transcend.",
        texto24:"Our track record speaks for us",
        texto25:"Our team has 30 years of experience in the financial and protection world.",
        texto26:"This gives us a broad overview to outline the growth path for the coming years, a formula that combines expertise, technology and innovation.",
        texto27:"EXPERTISE + TECHNOLOGY + INNOVATION",
        texto28:"We do what we love",
        texto29:"We design, develop and implement Insurtech solutions that respond to new demands or market niches, becoming a great opportunity for our partners.",
        texto30:"Solutions",
        texto31:"We are a team with extensive knowledge on the Insurtech and Fintech industries.",
        texto32:"We are united by our passion to solve complex business related to insurance, assistances and finance. We use technology to be on-top of the ordeal. ",
        texto33:"Contact us at",
        texto34:"To define us is to limit us. Besides, its not easy.",
        texto35:"We help the full Insurance ecosystem, supply chain and service chain to achieve better results and at the same time change the way people consume these services.",
        texto36:"We believe that the world deserves access to a fully protected life with a satisfying experience. We are developing tools to help on the commercialization and user experience processes. ",
        texto37:"We dive deep in the problem, disaggregate all of its components to find the best solution.",
        texto38:"Benefit from the Insurtech era with ACTO.",

        texto39:"Solutions ",
        texto40:"Corporate ",
        texto41:"We want to help you get to know your client in different ways and capitalize on that information",
        texto42:"Our job is creating diverse benefits to your company while improving your clients and collaborators life quality.",
        texto43:"product",
        texto44:"network capitalizing",
        texto45:"Our range of services and products allows us to create the best product for your network. We create it and you offer it as if it were your own, we operate. As simple as that. ",
        texto46:"B2B2C Alliances",
        texto47:"We collaborate with companies and groups of people that have the same interests and needs, which due to their business model they are a perfect distribution channel for insurance policies.",
        texto48:"Some of business models that can benefit from this B2B2C model are direct-selling and consumer/ retail, convenience stores, labor unions, banks, retail stores, FinTechs, e-commerce…",
        texto49:"4 simple steps to generate a product and receive diverse benefits",
        texto50:"Analyzing your network needs",
        texto51:"We do some data mining on peoples lifestyle and needs. We focus on improving lifestyle.",
        texto52:"Defining the chort by finding needs and risks",
        texto53:"Insurance and microinsurance",
        texto54:"Emergency Assistance",
        texto55:"Home assistances",
        texto56:"Travel assistance",
        texto57:"Loans",
        texto58:"Create and design the product",
        texto59:"Once we process your network needs, we have the best protection for them. Thanks to this step the onboarding and distribution becomes easier and have better results. We are offering them the products they need and desire.",
        texto60:"Thanks to this technology, it is not necessary to perform pilot tests on your model, but if so, we will do them.",
        texto61:"Product distribution",
        texto62:"We study the best practices to achieve the highest possible closing percentage.",
        texto63:"We distribute within your network on a massive scale.",
        texto64:"Our process starts with an informative campaign to let your network know the benefits that your company has achieved for them.",
        texto65:"Sales and tracking",
        texto66:"Once the sale has started, you will be able to:",
        texto67:"Managing your network",
        texto68:"Obtain in-depth analysis for decision making and visualize the best-selling products and services within your network.",
        texto69:"The daily collateral income for your company.",
        texto70:"Real-time tracking of clarifications, customer service and medical assistance requests.",
        texto71:"Enter specific instructions for your network to be notified via app.",
        texto72:"Technological Development and API's",
        texto73:"ACTO has the support, technology infrastructure and experience to integrate our technology platforms with APIs or develop the necessary tools to succeed. We also have a CRM for customer service, product management, payment follow-ups, data analytics and more. We offer sustainability in the face of innovation with an eye in User Experience.",
        texto74:"Technology Solutions",
        texto75:"Our technology allows us to distribute this products in a massive way without loosing user experience:",
        texto76:"App mobile, App web, SaaS y APIs.",
        texto77:"With them we create the most efficient and profitable ecosystem.",
        texto78:"Tools that allow us to better our process without having to develop inhouse solutions.",
        texto79:"Application Platform Interface that allows us to integrate with every app or web development to offer our products in a seamless way.",
        texto80:"e-commerce B2B2C",
        texto81:"Every e commerce may integrate into its shopping cart an insurance to its checkout process.",
        texto82:"Freight service insurance (transporte B2B)",
        texto83:"A platform that allows us to monitor truck fleet, manage products and calculate risk premiums.",
        texto84:"Mobile app for the end consumer, where he can buy the products chosen for him, manage his memberships and request emergency assistance.",
        texto85:"CRM platform that allows our B2B partner to manage its network, monitor in real time the follow-up of a claim and visualize the daily revenue per product sold.",
        texto86:"END CUSTOMER",
        texto87:"Acquire products immediately",
        texto88:"Request medical assistance 24/7",
        texto89:"Video Conferencing with doctor 24/7",
        texto90:"Request emergency help 24/7",
        texto91:"24/7 customer support",
        texto92:"Have the protection certificate in your cell phone or computer for any emergency.",
        texto93:"Receive plan expiration and attendance tracking alerts",
        texto94:"Claiming system based on the prevalidation of information",
        texto95:"B2B COMPANIES",
        texto96:"Control panel",
        texto97:"Product visualization",
        texto98:"Data analytics",
        texto99:"Real-time monitoring of emergency or medical assistance",
        texto100:"Request for new products",
        texto101:"Quality service review",
        texto102:"More information",
        texto103:"B2B COMPANIES",
        texto104:"Seamless adaptability to any application or website",
        texto105:"Immediate coverage to users",
        texto106:"Offering financial products, assistance and insurance designed for on-demand and as-needed applications",
        texto107:"Protection of products purchased by customers on the website",
        texto108:"Rental properties",
        texto109:"Travel and air tickets",
        texto110:"Event or services",
        texto111:"Enterprise Solutions",
        texto112:"Our API allows you to protect your customers quickly and easily.",
        texto113:"We improve the lives of your customers while generating profits for you.",
        texto114:"No matter what industry you are in, your customers will be able to access fast insurance on your platform or online store with a few clicks. Easy to integrate and with collateral gains.",
        texto115:"Legal advice and contracts",
        texto116:"Thanks to our long trajectory we have the most attractive corporate contracts in the insurance and financial market that allow us to extend our legal benefits to you by being part of our B2B alliance.",
        texto117:"In addition, we provide corporate legal consulting services specialized in insurance and financial matters.",
        texto118:"Our lawyers have more than 29 years of legal experience in the insurance and financial sector.",
        texto119:"SOLUTIONS FOR",
        texto120:"ASSOCIATES",
        texto121:"At ACTO we seek to improve the quality of life starting with our environment.",
        texto122:"That is why we seek to partner with people who share our ideas and values, so that together we can build a better future and maximize our abilities.",
        texto123:"Courses",
        texto124:"We have developed training plans that combine 30 years of experience, the use of new technologies and the design of strategies that allow you to face great challenges in a structured way. Our courses are certified by Cardone University.",
        texto125:"We understand that freedom allows us to create, grow and transcend.",
        texto126:"And we are aware that there are limits and risks that we cannot control, but we can be prepared to face them.",
        texto127:"At ACTO, we have created protection and assistance plans so that your clients and collaborators have a better life.",
        texto128:"No complicated words, no small print.",
        texto129:"At ACTO we offer technological solutions to integrate the financial, insurance and assistance industries into your company's operations.",
        texto130:"B2B Solutions",

        texto131:"An act of trust to support your business, project or idea.",
        texto132:"Learn about our types of surety bonds",
        texto133:"Administrative bonds",
        texto134:"Ensures adequate investments, delivery of goods and services, quality of materials used and employees, etc.",
        texto135:"Credit Bonds",
        texto136:"Guarantees the payment of a loan for the supply of fuel.",
        texto137:"Fidelity bonds",
        texto138:"Protect your assets from employees who steal or commit fraud.",
        texto139:"Judicial bonds",
        texto140:"Ensures proper legal proceedings, if any.",
        texto141:"Other products and services",
        texto142:"Specific program developed by ACTO for the adequate control of suppliers.",
        texto143:"Write to us for more information",
        texto144:"Leave us a message, we will get back to you very soon.",
    
        texto145:"Frequently Asked Questions",
        texto146:"Frequently Asked Questions",
        texto147:"Companies",
        texto148:"Associates",
        texto149:"Insurers",
        texto150:"My account",
        texto151:"my credits",
        texto152:"Tax Center",
        texto153:"WHAT IS AN ACT GROUP?",
        texto154:"We are a multidisciplinary team of experts in Insurtech and data science that designs and develops adaptable, accessible and scalable technological solutions.",
        texto155:"WHAT DO WE DO AS A GROUP?",
        texto156:"We want you to know your customer in depth and capitalize on that information. We design, develop and implement Insurtech solutions that respond to new demands or market niches.",
        texto157:"WHAT IS OUR MISSION?",
        texto158:"Generate new collateral revenues for our corporate partners.",
        texto159:"WHAT IS INSURTECH?",
        texto160:"Insurtech is the union of the words 'Insurance and technology'. This term defines the sector that brings together traditional insurance companies, technology companies and disruptive startups that use new technologies such as blockchains, big data or cloud computing to create new ways of offering products and customer service to the end customer within the insurance sector.",
        texto161:"WHAT IS DATA SCIENCE?",
        texto162:"Data science combines multiple fields including statistics, scientific methods and data analysis to extract value from data.",
        texto163:"WHAT KIND OF TECHNOLOGICAL SOLUTIONS DO WE OFFER?",
        texto164:"MORE RELATED TOPICS",
        texto165:"How can I view or edit my account information?",
        texto166:"Can I cancel a payment I have already sent by Acto?",
        texto167:"My payment is being held because a dispute, claim or chargeback has been opened. Why?",
        texto168:"How can I associate a bank account to my Acto account?",
        texto169:"How can I link a debit or credit card to my account?",
        texto170:"Tell us more about your problem",
        texto171:"CALL US",
        texto172:"WRITE TO US",
        texto173:"",

    },
    'es': {
        soluciones: "Soluciones",
        empresas: "Para Empresas",
        aseguradoras: "Para Aseguradoras",
        asociados: "Para Asociados",
        nosotros: "Nosotros",
        membresias: "Membresias",
        seguros: "Seguros",
        salud: "Salud",
        vida: "Vida",
        hogar: "Hogar",
        empresa: "Empresa",
        auto: "Auto",
        motocicleta: "Motocicleta",
        viaje: "Viaje",
        mascota: "Mascota",
        fianzas: "Fianzas",
        blog: "Blog",
        login: "Login",
        contacto: "Contacto",
        idioma: "Idioma",
        header1: "Todo",
        header2: "gran cambio",
        header3: "inicia por un ",
        text: {
            link:{
                web: "Visitar sitio web",
            },
        },
        home: {
            ourBrands: "Nuestras marcas",
            motivation: "Nos motiva el desarrollo de soluciones para problemas que conocemos a fondo."
        },
        brands: "Marcas",
        btn: {
            header: "Descubre más"
        },
        texto1: "Todo gran cambio inicia por un ",
        texto2: "Nuestro propósito es mejorar la calidad de vida a través de la libertad financiera de manera fácil y accesible",
        texto3: "Conectamos a empresas y personas con las industrias InsurTech y FinTech por medio de la tecnología y soluciones creativas",
        copyright: "TODOS LOS DERECHOS RESERVADOS",
        correo: "Correo de contacto",
        phone1: "Telefono de contactos",
        phone2: "Atencion al cliente",
        productos: "PRODUCTOS",
        entera: "SEGURO VIDA ENTERA",
        devolucion: "SEGURO CON DEVOLUCIÓN",
        jubilacion: "SEGURO JUBILACIÓN",
        universidad: "SEGURO UNIVERSIDAD",
        membresias_footer: "MEMBRESÍAS Y ASISTENCIAS",
        trayectoria: "TRAYECTORIA",
        que_hacemos: "QUÉ HACEMOS",
        clientes: "CLIENTES",
        privacidad: "AVISO DE PRIVACIDAD",
        app: "DESCARGA LA APP",
        texto4:"Tenemos más de 30 años de experiencia lo que nos permite entender los nuevos retos",
        texto5:" Sabemos que los cambios sociales y tecnológicos han generado mayor exigencia en tiempos, servicio y eficiencia, por lo cual hemos diseñado herramientas que nos permiten ser mas rápidos en los procesos, más eficientes en la comunicación y más humanos en el servicio",
        texto6:"Hagamos equipo, podemos ayudarte a mejorar procesos, entender nuevos nichos de mercado y la forma de llegar a ellos, evolucionar tu modelo de negocios y establecer estrategias integrales que reduzcan el tiempo de obtención de resultados con visión de largo plazo",
        texto7:"Soluciones tecnológicas",
        texto8:" Insurtech se define como el uso de herramientas tecnológicas que han evolucionado la forma de generar cobertura y apertura de nuevos mercados en el mundo de las aseguradoras. En ACTO tenemos un equipo que diseña herramientas disruptivas que permiten una mayor penetración y manejo de información del mercado.",
        texto9:"Utilización de nuestro sistema de e-comerce, servicio al cliente y sistema de predicción de fraudes para la distribución y atención de productos de manera digital.",
        texto10:"Las API permiten que sus productos y servicios se comuniquen con otros, sin necesidad de saber cómo están implementados. Esto simplifica el desarrollo de las aplicaciones y permite ahorrar tiempo y dinero en las integraciones.",
        texto11:"Venta",
        texto12:"Ayudamos a clientes a que puedan integrar la industria de los seguros en su e-commerce de diversas maneras.",
        texto13:"Uso de plataforma app Mobile para incentivar la compra o comunicarnos de manera eficiente, eficaz y oportuna.",
        texto14:"Utilizamos un CRM diseñado para mejorar la comunicación con el usuario final. Brindamos eficiencia por las respuestas automáticas y simpatía por la atención personalizada y humanizada.",
        texto15:"Hoy más que nunca la información es el activo más valioso y somos muy consientes de eso. Gracias a nuestros diferentes modelos de negocio, podemos obtener información limpia, organizada y lista para capitalizar.",
        texto16:"Esta industria nos permite ver el futuro con mejores esperanzas en términos de salud y calidad de vida.",
        texto17:"La misión de ACTO es llegar a ser una de las empresas mas importantes en el mundo en esta industria.",
        texto18:"Las posibilidades son ilimitadas, entendemos que, en la innovación apoyada de la estructura de las aseguradoras, logrará que esta industria sea el próximo big wave.",
        texto19:"SOLUCIONES",
        texto20:"Somos un equipo multidisciplinario experto en Insurtech, que diseña y desarrolla soluciones tecnológicas adaptables,accesibles y escalables.",
        texto21:"Nos motiva el desarrollo de soluciones para problemas que conocemos a fondo.",
        texto22:"Por que sabemos que cada ACTO que mejora la vida de una persona, se convertirá en una tendencia que multiplicada por miles, mejorará la calidad de vida de una sociedad.",
        texto23:"En ACTO apostamos por la innovación como el camino para transformar y trascender.",
        texto24:"Nuestra trayectoria habla por nosotros",
        texto25:"Nuestro equipo cuenta con 30 años de experiencia en el mundo financiero y de protección.",
        texto26:"Lo que nos permite un amplio panorama para trazar la ruta de crecimiento de los próximos años, una fórmula que combina expertise, tecnología e innovación.",
        texto27:"EXPERTISE + TECNOLOGÍA + INNOVACIÓN",
        texto28:"Hacemos lo que más nos gusta",
        texto29:"Diseñamos, desarrollamos e implementamos soluciones Insurtech que responden a nuevas demandas o nichos de mercado, convirtiéndose en una gran oportunidad para nuestros aliados.",
        texto30:"Soluciones",
        texto31:"Somos un equipo con amplio conocimiento de las industrias Insurtech y Fintech",
        texto32:"Estamos unidos por la pasión de facilitar el complejo negocio de los seguros, las asistencias y las finanzas a través de la innovación y  tecnología intuitiva.",
        texto33:"Contáctanos",
        texto34:"No trates de definirnos, no es sencillo.",
        texto35:"Queremos ayudar a todas las cadenas de suministro y servicio que integran a las industrias financieras, seguros y asistencias a lograr mejores resultados y al mismo tiempo cambiar la manera en que consumimos estos productos y servicios.",
        texto36:"Creemos que el mundo merece una mejor experiencia y nosotros estamos aquí para apoyar a todo aquel que sea parte de la industria y quiera mejorar sus practicas.",
        texto37:"Nosotros somos el frente y el fondo, encontramos el problema y ofrecemos las soluciones",
        texto38:"Apóyate de ACTO y conquista tu futuro.",
        texto39:"Soluciones para ",
        texto40:"Empresas",
        texto41:"Queremos que conozcas a tu cliente y capitalices esa información.",
        texto42:"Nos dedicamos a crear nuevos ingresos colaterales para tu empresa mientras mejoramos la calidad de vida de tus clientes y colaboradores.",
        texto43:"producto",
        texto44:"Capitaliza tu red",
        texto45:"Nuestra gama de opciones nos permite crear el mejor producto para tu red. Nosotros lo creamos y tu lo ofreces como si fuera propio. Así de simple.",
        texto46:"ALIANZAS B2B",
        texto47:"Generamos alianzas comerciales con empresas y grupos de interés en común, que por sus giros son los perfectos canales de distribución de nuestros productos.",
        texto48:"Ventas por catálogo, multinivel, tiendas de autoservicio, sindicatos, bancos, financieras, tiendas departamentales, FinTech, E-commerce, por nombrar algunos ejemplos ",
        texto49:"4 sencillos pasos para generar tu producto y tener un nuevo ingreso colateral.",
        texto50:"Estudiamos tu red y sus necesidades",
        texto51:"Estudiamos a tu red, basándonos en su manera de vivir y enfocándonos en mejorar su calidad de vida.",
        texto52:"Encontramos el nicho y las necesidades que atacaremos.",
        texto53:"Seguros y microseguros",
        texto54:"Asistencias de emergencia",
        texto55:"Asistencias en el hogar",
        texto56:"Asistencia de viaje",
        texto57:"Créditos",
        texto58:"Creamos y diseñamos los productos",
        texto59:"Una vez procesada la información de tu red, nuestras plataformas de análisis de datos nos lanzarán la información del producto que más se adapte a las necesidades de tu red.",
        texto60:"Gracias a esta tecnología no es necesario realizar pruebas piloto en tu modelo pero de ser así, las hacemos.",
        texto61:"Distribución de los productos",
        texto62:"Estudiamos las mejores practicas para lograr el mayor porcentaje de cierre posible.",
        texto63:"Distribuimos dentro de tu red de manera masiva.",
        texto64:"Nuestro proceso inicia con una campaña informativa para que tu red sepa los beneficios que tu empresa ha conseguido para ellos.",
        texto65:"Venta y seguimiento",
        texto66:"Comenzada la venta, podrás:",
        texto67:"Administración de tu red",
        texto68:"Obtener análisis profundos para la toma de decisiones y vizualizar los productos y servicios más vendidos dentro de tu red.",
        texto69:"El ingreso colateral diario para tu empresa.",
        texto70:"Seguimiento en tiempo real de aclaraciones, servicio al cliente y solicitud de asistencia médica.",
        texto71:"Ingresar instrucciones específicas para que tu red sea notifica vía app.",
        texto72:"Desarrollo Tecnológico y API’s",
        texto73:"En ACTO contamos con el soporte, infraestructura y la experiencia para integrar nuestras plataformas tecnológicas App mobile, App web y API’S a tu modelo, con un plataforma modificable, CRM para atención al cliente, administración de productos, pagos digitales, data analitycs, servicios y más, ofreciendo sostenibilidad ante la innovación y la mejor experiencia a tus usuarios.",
        texto74:"Soluciones Tecnológicas",
        texto75:"Nuestra tecnología nos permite distribuir los productos de manera masiva al usar nuestras plataformas tecnológicas:",
        texto76:"App mobile, App web, SaaS y APIs.",
        texto77:"Con ellas creamos el ecosistema más eﬁciente y redituable",
        texto78:"Herramientas tecnológicas que nos permiten mejorar los procesos.",
        texto79:"Application Platform Interface que nos permite integrarnos a todas las plataformas y sitios web para ofrecer nuestros productos con un solo click.",
        texto80:"Venta E-Commerce B2B",
        texto81:"Toda plataforma E-Commerce podria integrar a su carrito de compras nuestra API.",
        texto82:"Transporte B2B",
        texto83:"Plataforma tecnológica que nos permite monitorear flotillas de camiones, administrar productos y calcular las primas por riesgo.",
        texto84:"App mobile para el consumidor final, donde podrá comprar los productos elegidos para él, administrar sus membresias y solicitar asistencias de emergencia.",
        texto85:"Plataforma CRM que le permite a nuestro aliado B2B administrar su red, monitorear en tiempo real el seguimiento de una reclamacion y visualizar los ingresos diario por producto vendido.",
        texto86:"CLIENTE FINAL",
        texto87:"Adquirir productos inmediatamente",
        texto88:"Solicitar ayuda médica 24/7",
        texto89:"Hacer VideoConferencia con doctor 24/7",
        texto90:"Solicitar ayuda de emergencia 24/7",
        texto91:"Servicio al cliente 24/7",
        texto92:"Tener el certificado de protección en celular o computadora para cualquier emergencia",
        texto93:"Recibir alertas de vencimiento de plan y seguimiento de asistencia",
        texto94:"Sistema de reclamación basada en la prevalidación de información",
        texto95:"EMPRESAS B2B",
        texto96:"Panel de control",
        texto97:"Visualización de productos",
        texto98:"Data analytics",
        texto99:"Seguimiento tiempo real de emergencia o asistencia médica",
        texto100:"Solicitud de nuevos productos",
        texto101:"Revisión de calidad de servicio",
        texto102:"Más información",
        texto103:"EMPRESAS B2B",
        texto104:"Adaptabilidad a la perfección de cualquier aplicación o sitio web",
        texto105:"Cobertura inmediata a usuarios",
        texto106:"Ofrecer productos financieros, asistencias y seguros diseñados para solicitar al momento y cuando se necesiten",
        texto107:"Protección de productos que los clientes compren en sitio web",
        texto108:"Propiedades en renta",
        texto109:"Viajes y boletos de avión",
        texto110:"Evento o servicios",
        texto111:"Soluciones para Empresas",
        texto112:"Nuestra API permite proteger a tus clientes de manera rápida y fácil.",
        texto113:"Mejoramos la vida de tus clientes mientras te generamos ganancias",
        texto114:"Sin importar la industria en la que te encuentres, tus clientes podrán tener acceso a seguros rápidos en tu plataforma o tienda en línea con pocos clics. Fácil de integrar y con ganancias colaterales.",
        texto115:"Asesoría legal y contratos",
        texto116:"Gracias a nuestra larga trayectoria contamos los más atractivos contratos corporativos en el mercado asegurador y financiero que nos permiten extenderte nuestros beneficios legales al ser parte de nuestra alianza B2B.",
        texto117:"Además, otorgamos servicios de consultaría legal corporativa especializada en seguros y temas financieros.",
        texto118:"Nuestros abogados cuentan con más de 29 años de experiencia legal en el sector asegurador y financiero.",
        texto119:"SOLUCIONES PARA",
        texto120:"ASOCIADOS",
        texto121:"En ACTO buscamos mejorar la calidad de vida comenzando por nuestro entorno",
        texto122:"Por lo cual buscamos asociarnos con personas que compartan nuestras ideas y valores, para que juntos podamos construir un mejor futuro y potencializar al máximo nuestras habilidades.",
        texto123:"Cursos",
        texto124:"Hemos desarrollado planes de capacitación, que combinan la experiencia de 30 años, el uso de nuevas tecnologías y el diseño de estrategias que te permiten afrontar grandes retos de una forma estructurada. Nuestros cursos se encuentran certificados por Cardone University.",
        texto125:"Entendemos que la libertad nos permite crear, crecer y trascender",
        texto126:"Y estamos concientes que existen límites y riegos que no podemos controlar, pero si podemos estar preparados para afrontarlos.",
        texto127:"En ACTO, hemos creado planes de protección y asistencias para que tus clientes y colaboradores tengan una vida mejor",
        texto128:"Sin palabras complicadas, sin letras chiquitas.",
        texto129:"En ACTO ofrecemos soluciones tecnológicas para integrar la industria financiera, seguros y asistencias en las operaciones de tu empresa.",
        texto130:"Soluciones B2B",

        texto131:"Un acto de confianza para apoyar tu negocio, proyecto o idea",
        texto132:"Conoce nuestros tipos de fianzas",
        texto133:"Fianzas administrativas",
        texto134:"Garantiza las adecuadas inversiones, de la entrega de bienes y servicios, de la calidad de los materiales usadosy de los empleados, etc.",
        texto135:"Fianzas de crédito",
        texto136:"Garantiza el pago de un crédito destinado al suministro de combustible.",
        texto137:"Fianzas de fidelidad",
        texto138:"Protege tu patrimonio de empleados que roben o cometan fraude.",
        texto139:"Fianzas judiciales",
        texto140:"Garantiza el adecuado procedimiento judicial, en caso de que exista.",
        texto141:"Otros productos y servicios",
        texto142:"Programa específico desarrollado por ACTO para el adecuado control de los proveedores.",
        texto143:"Escríbenos para obtener más información",
        texto144:"Déjanos un mensaje, te responderemos muy pronto",

        texto145:"Preguntas frecuentes",
        texto146:"Preguntas más comunes",
        texto147:"Empresas",
        texto148:"Asociados",
        texto149:"Aseguradoras",
        texto150:"Mi cuenta",
        texto151:"Mis pagos",
        texto152:"Centro tributario",
        texto153:"¿QUÉ ES GRUPO ACTO?",
        texto154:"Somos un equipo multidisciplinario experto en Insurtech y data science, que diseña y desarrolla soluciones tecnológicas adaptables, accesibles y escalables",
        texto155:"¿QUÉ HACEMOS EN GRUPO ACTO?",
        texto156:"Queremos que conozcas a fondo a tu cliente y capitalices esa información. Diseñamos, desarrollamos e implementamos soluciones Insurtech que responden a nuevas demandas o nichos de mercado.",
        texto157:"¿CUÁL ES NUESTRA MISIÓN?",
        texto158:"Generar nuevos ingresos colaterales para nuestros aliados corporativos",
        texto159:"¿QUÉ ES INSURTECH ?",
        texto160:"Insurtech es la union de las palabras en ingles 'Insurance y technology'. Este termino define al sector que aglutina a las compañias de seguros tradicionales, a las empresas tecnologicas y a las startups disruptivas que utilizan las nuevas tecnologias como los blockchains, big data o la computacion en la nube para crear dentro del sector de los seguros nuevas formas de ofrecer productos y servicio al cliente al cliente final.",
        texto161:"¿QUÉ ES DATA SCIENCE?",
        texto162:"La ciencia de datos combina multiples camplos que incluyen estadísticas, métodos cienctíficos y análisis de datos para extraer el valor de los datos.",
        texto163:"¿QUE TIPO DE SOLUCIONES TECNOLOGICAS OFRECEMOS?",
        texto164:"MÁS TEMAS RELACIONADOS",
        texto165:"¿Cómo puedo ver o editar la información de mi cuenta?",
        texto166:"¿Puedo cancelar un pago que ya envié por Acto?",
        texto167:"Mi pago está retenido porque se ha abierto una disputa, una reclamación o una devolución de cargo. ¿Por qué?",
        texto168:"¿Cómo puedo asociar una cuenta bancaria a mi cuenta de Acto?",
        texto169:"¿Cómo puedo asociar una tarjeta de débito o de crédito a mi cuenta?",
        texto170:"Cuéntanos más sobre tu problema",
        texto171:"LLÁMANOS",
        texto172:"ESCRÍBENOS",
        texto173:"",








    }
};

const i18n = new VueI18n({
    locale: 'es', // set locale
    //locale: window.navigator.language.split('-')[0],
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
});




export default i18n;