<template>
<div id="view-companies">
    <div class="position-relative">
        <div class="container">
            <div class="row section-header-height align-content-center">
                <div class="text-center col-lg-12 aos-init aos-animate" data-aos="fade-in">
                    <h6 id="in" class="page-title">{{ $t('texto39') }} <br>{{ $t('texto40') }}</h6>
                    <h2>{{ $t('texto41') }}</h2>
                    <p class="mb-5">
                        {{ $t('texto42') }}
                    </p>
                    <a class="btn btn-primary btn-lg btn-transparent mb-3">
                        <router-link to="/contacto">{{ $t('texto33') }}</router-link>
                    </a>
                    <br>
                    <br>
                    <h5 id="in" class="mb-5">
                        Data mining <br>+ <br>{{ $t('texto43') }}<br>
                        =<br>
                        {{ $t('texto44') }}
                    </h5>
                </div>
            </div>
        </div>
    </div>
    <div class="position-relative">
        <div class="container">
            <div class="row section-header-height align-content-center">
                <div class="text-center col-lg-8 offset-lg-2 aos-init aos-animate" data-aos="fade-in">
                    <div class="col-lg-12">
                        <img id="circles_img" :src="require('@/assets/images/circles.png')">
                    </div>
                    <br>
                    <br>
                    <p class="mb-5">
                        {{ $t('texto45') }}
                    </p>
                    <a class="btn btn-primary btn-lg btn-transparent mb-3">
                        <router-link to="/contacto">{{ $t('texto33') }}</router-link>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="position-relative">
        <div class="container">
            <div class="row section-header-height align-content-center">
                <div class="text-center col-lg-12 aos-init aos-animate" data-aos="fade-in">
                    <h6 id="in" class="page-title">{{ $t('texto46') }}</h6>
                    <h2 class="mb-4">{{ $t('texto47') }}</h2>
                    <p class="mb-5">
                        {{ $t('texto48') }}</p>
                    <a class="btn btn-primary btn-lg btn-transparent mb-3">
                        <router-link to="/contacto">{{ $t('texto33') }}</router-link>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <br>
    <br>
    <div class="position-relative">
        <div class="container">
            <div class="row section-full-height align-content-center">
                <div class="text-center col-lg-8 offset-lg-2 aos-init aos-animate" data-aos="fade-in">
                    <h4 class="mb-4">{{ $t('texto49') }}</h4>
                </div>
                <div class="col-lg-3 transition2">
                    <div class="card card-double">
                        <div class="card-header">
                            <img :src="require('@/assets/images/solutions/icon/icon-estudiamos.svg')" class="img-fluid mb-3" width="90">
                            <p>{{ $t('texto50') }}</p>
                        </div>
                        <div class="card-body list">
                            <p>
                                {{ $t('texto51') }}
                            </p>
                            <p>
                                {{ $t('texto52') }}
                            </p>
                            <div class="list-item d-flex">
                                <div class="roman-bullet">I</div>
                                <div>{{ $t('texto51') }}</div>
                            </div>
                            <div class="list-item d-flex">
                                <div class="roman-bullet">II</div>
                                <div>{{ $t('texto54') }}</div>
                            </div>
                            <div class="list-item d-flex">
                                <div class="roman-bullet">III</div>
                                <div>{{ $t('texto55') }}</div>
                            </div>
                            <div class="list-item d-flex">
                                <div class="roman-bullet">IV</div>
                                <div>{{ $t('texto56') }}</div>
                            </div>
                            <div class="list-item d-flex">
                                <div class="roman-bullet">V</div>
                                <div>{{ $t('texto57') }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 transition2">
                    <div class="card card-double">
                        <div class="card-header">
                            <img :src="require('@/assets/images/solutions/icon/icon-creamos.svg')" class="img-fluid mb-3" width="90">
                            <p>{{ $t('texto58') }}</p>
                        </div>
                        <div class="card-body list">
                            <p>
                                {{ $t('texto59') }}
                            </p>
                            <p>
                                {{ $t('texto60') }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 transition2">
                    <div class="card card-double">
                        <div class="card-header">
                            <img :src="require('@/assets/images/solutions/icon/icon-distribucion.svg')" class="img-fluid mb-3" width="90">
                            <p>{{ $t('texto61') }}</p>
                        </div>
                        <div class="card-body list">
                            <p>
                                {{ $t('texto62') }}
                            </p>
                            <p>
                                {{ $t('texto63') }}
                            </p>
                            <p>
                                {{ $t('texto64') }}
                            </p>
                        </div>
                    </div>
                </div>
                <div id="cards" class="col-md-6 col-lg-3 transition2">
                    <div class="card card-double">
                        <div class="card-header">
                            <img :src="require('@/assets/images/solutions/icon/icon-venta.svg')" class="img-fluid mb-3">
                            <p>{{ $t('texto65') }}</p>
                        </div>
                        <div class="card-body list">
                            {{ $t('texto66') }}
                            <br>
                            <div class="list-item d-flex">
                                <div class="roman-bullet">I</div>
                                <div>{{ $t('texto67') }}</div>
                            </div>
                            <div class="list-item d-flex">
                                <div class="roman-bullet">II</div>
                                <div>
                                    {{ $t('texto68') }}
                                </div>
                            </div>
                            <div class="list-item d-flex">
                                <div class="roman-bullet">III</div>
                                <div>
                                    {{ $t('texto69') }}
                                </div>
                            </div>
                            <div class="list-item d-flex">
                                <div class="roman-bullet">IV</div>
                                <div>
                                    {{ $t('texto70') }}
                                </div>
                            </div>
                            <div class="list-item d-flex">
                                <div class="roman-bullet">V</div>
                                <div>
                                    {{ $t('texto71') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="position-relative">
        <div class="container">
            <div class="row section-header-height align-content-center">
                <div class="text-center col-lg-8 offset-lg-2 aos-init aos-animate" data-aos="fade-in">
                    <h3>{{ $t('texto72') }}</h3>
                    <p class="mb-5">
                        {{ $t('texto73') }}</p>
                    <a class="btn btn-primary btn-lg btn-transparent mb-3">
                        <router-link to="/contacto">{{ $t('texto33') }}</router-link>
                    </a>
                    <br>
                    <br>
                    <img id="computer" :src="require('@/assets/images/computer.png')" class="col-lg-12">
                </div>
            </div>
        </div>
    </div>
    <br>
    <br>
    <br>
    <div class="position-relative">
        <div class="container">
            <div class="row section-full-height align-content-center">
                <div class="text-center col-lg-8 offset-lg-2 aos-init aos-animate" data-aos="fade-in">
                    <h3 class="mb-4">{{ $t('texto74') }}</h3>
                    <p class="mb-5">{{ $t('texto75') }}</p>
                    <h4>{{ $t('texto76') }}</h4>
                    <br>
                    <p class="mb-5">{{ $t('texto77') }}</p>
                </div>
                <div class="col-lg-4 transition2">
                    <div class="card card-double">
                        <div class="card-header text-center">
                            <p class="mb-6">Software (SaaS)</p>
                            <!-- <img :src="require('@/assets/images/solutions/icon/icon-estudiamos.svg')" class="img-fluid mb-6" width="90"> -->
                        </div>
                        <div class="card-body list">
                            <p>
                                {{ $t('texto78') }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 transition2">
                    <div class="card card-double">
                        <div class="card-header text-center">
                            <p class="mb-6">API</p>
                            <!-- <img :src="require('@/assets/images/solutions/icon/icon-estudiamos.svg')" class="img-fluid mb-6" width="90"> -->
                        </div>
                        <div class="card-body list">
                            <p>
                                {{ $t('texto79') }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 transition2">
                    <div class="card card-double">
                        <div class="card-header text-center">
                            <p class="mb-6">{{ $t('texto80') }}</p>
                            <!-- <img :src="require('@/assets/images/solutions/icon/icon-estudiamos.svg')" class="img-fluid mb-6" width="90"> -->
                        </div>
                        <div class="card-body list">
                            <p>
                                {{ $t('texto81') }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 transition2">
                    <div class="card card-double">
                        <div class="card-header text-center">
                            <p class="mb-6">{{ $t('texto82') }}</p>
                            <!-- <img :src="require('@/assets/images/solutions/icon/icon-estudiamos.svg')" class="img-fluid mb-6" width="90"> -->
                        </div>
                        <div class="card-body list">
                            <p>
                                {{ $t('texto83') }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 transition2">
                    <div class="card card-double">
                        <div class="card-header text-center">
                            <p class="mb-6">App Mobile B2C y App Web B2C</p>
                            <!-- <img :src="require('@/assets/images/solutions/icon/icon-estudiamos.svg')" class="img-fluid mb-6" width="90"> -->
                        </div>
                        <div class="card-body list">
                            <p>
                                {{ $t('texto84') }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 transition2">
                    <div class="card card-double">
                        <div class="card-header text-center">
                            <p class="mb-6">App Web B2B</p>
                            <!-- <img :src="require('@/assets/images/solutions/icon/icon-estudiamos.svg')" class="img-fluid mb-6" width="90"> -->
                        </div>
                        <div class="card-body list">
                            <p>
                                {{ $t('texto85') }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 transition2">
                    <div class="card card-single">
                        <div class="card-header">
                            <img :src="require('@/assets/images/iconos/icono-cliente.svg')" class="img-fluid mb-4" width="90">
                            <p>App Mobile / App Web</p>
                            <small class="font-weight-bold">{{ $t('texto86') }}</small>
                        </div>
                        <hr>
                        <div class="card-body list">
                            <ul>
                                <li class="mb-3">{{ $t('texto87') }}</li>
                                <li class="mb-3">{{ $t('texto88') }}</li>
                                <li class="mb-3">{{ $t('texto89') }}</li>
                                <li class="mb-3">{{ $t('texto90') }}</li>
                                <li class="mb-3">{{ $t('texto91') }}</li>
                                <li class="mb-3">{{ $t('texto92') }}</li>
                                <li class="mb-3">{{ $t('texto93') }}</li>
                                <li class="mb-3">{{ $t('texto94') }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 transition2">
                    <div class="card card-single">
                        <div class="card-header">
                            <img :src="require('@/assets/images/iconos/icono-apps-nube.svg')" class="img-fluid mb-4" width="90">
                            <p>App Web</p>
                            <small class="font-weight-bold">{{ $t('texto95') }}</small>
                        </div>
                        <hr>
                        <div class="card-body list">
                            <ul class="mb-4">
                                <li class="mb-3">{{ $t('texto96') }}</li>
                                <li class="mb-3">{{ $t('texto97') }}</li>
                                <li class="mb-3">{{ $t('texto98') }}</li>
                                <li class="mb-3">{{ $t('texto99') }}</li>
                                <li class="mb-3">{{ $t('texto100') }}</li>
                                <li class="mb-3">{{ $t('texto101') }}</li>
                            </ul>
                            <a class="btn btn-primary btn-lg btn-transparent mb-3 d-block mx-auto">
                                <router-link to="/contacto">{{ $t('texto102') }}</router-link>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 transition2">
                    <div class="card card-single">
                        <div class="card-header">
                            <img :src="require('@/assets/images/iconos/icono-empresas-b2b.svg')" class="img-fluid mb-4" width="90">
                            <p>API</p>
                            <small class="font-weight-bold">{{ $t('texto103') }}</small>
                        </div>
                        <hr>
                        <div class="card-body list">
                            <ul>
                                <li class="mb-3">{{ $t('texto104') }}</li>
                                <li class="mb-3">{{ $t('texto105') }}</li>
                                <li class="mb-3">
                                    {{ $t('texto106') }}
                                </li>
                                <li class="mb-3">
                                    {{ $t('texto107') }}
                                    <div class="list-item d-flex">
                                        <div class="roman-bullet">I</div>
                                        <div>E-Commerce</div>
                                    </div>
                                    <div class="list-item d-flex">
                                        <div class="roman-bullet">II</div>
                                        <div>{{ $t('texto108') }}</div>
                                    </div>
                                    <div class="list-item d-flex">
                                        <div class="roman-bullet">III</div>
                                        <div>{{ $t('texto109') }}</div>
                                    </div>
                                    <div class="list-item d-flex">
                                        <div class="roman-bullet">IV</div>
                                        <div>{{ $t('texto110') }}</div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="position-relative">
        <div class="container">
            <div class="row section-header-height align-content-center">
                <div class="text-center col-lg-12 aos-init aos-animate" data-aos="fade-in">
                    <h6 id="in" class="page-title">{{ $t('texto111') }}</h6>
                    <h1 class="mb-4">{{ $t('texto112') }}</h1>
                    <a class="btn btn-primary btn-lg btn-transparent mb-3">
                        <router-link to="/contacto">{{ $t('texto33') }}</router-link>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="position-relative">
        <div class="container">
            <div class="row section-full-height">
                <div class="col-lg-12 align-self-center aos-init aos-animate" data-aos="fade-in">
                    <h2>{{ $t('texto113') }}</h2>
                    <p class="mb-5">
                        {{ $t('texto114') }}
                    </p>
                    <div class="row">
                        <div class="col-3">
                            <img :src="require('@/assets/images/iconos/icono-camara.svg')" class="d-block mx-auto mb-5" height="70">
                        </div>
                        <div class="col-3">
                            <img :src="require('@/assets/images/iconos/icono-llave.svg')" class="d-block mx-auto mb-5" height="70">
                        </div>
                        <div class="col-3">
                            <img :src="require('@/assets/images/iconos/icono-casa.svg')" class="d-block mx-auto mb-5" height="70">
                        </div>
                        <div class="col-3">
                            <img :src="require('@/assets/images/iconos/icono-universidad.svg')" class="d-block mx-auto mb-5" height="70">
                        </div>
                        <div class="col-3">
                            <img :src="require('@/assets/images/iconos/icono-viaje.svg')" class="d-block mx-auto mb-5" height="70">
                        </div>
                        <div class="col-3">
                            <img :src="require('@/assets/images/iconos/icono-avion.svg')" class="d-block mx-auto mb-5" height="70">
                        </div>
                        <div class="col-3">
                            <img :src="require('@/assets/images/iconos/icono-ahorros.svg')" class="d-block mx-auto mb-5" height="70">
                        </div>
                        <div class="col-3">
                            <img :src="require('@/assets/images/iconos/icono-diamante.svg')" class="d-block mx-auto mb-5" height="70">
                        </div>
                    </div>

                    <a class="btn btn-primary btn-lg btn-transparent mb-3 d-block mx-auto">
                        <router-link to="/contacto">{{ $t('texto33') }}</router-link>
                    </a>
                    <br>
                    <br>
                    <br>
                    <br>

                </div>

                <div class="col-lg-12 align-self-center order-0 order-lg-1 aos-init aos-animate" data-aos="fade-in">
                    <h4 class="font-weight-bold">{{ $t('texto115') }}</h4>
                    <p>
                        {{ $t('texto116') }}
                    </p>
                    <h5 style="color: #006AD7;">
                        {{ $t('texto117') }}
                    </h5>
                    <p>
                        {{ $t('texto118') }}
                    </p>

                </div>
            </div>
        </div>
        <br>
        <br><br>
    </div>

</div>
</template>

<script>
export default {
    name: "Empresas",
    components: {

    },
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'Soluciones para Empresas',
        // all titles will be injected into this template
        titleTemplate: 'Empresas | Grupo ACTO',
        link: [{
            rel: 'canonical',
            href: 'https://grupoacto.com/soluciones/empresas'
        }],
        description: 'Soluciones para empresas, buscando siempre inovar en tecnologia y seguridad para tu empresa.'
    }

}
</script>

<style>
#in {
    color: #5ABA56;
}

#cards {
    z-index: 3;
}

#circles_img {
    width: 100%;
}

@media (max-width:999px) {
    #circles_img {
        width: 100%;
    }

}
</style>
