<template>
<div id="view-memberships">

    <div class="position-relative">
        <div class="container">
            <div class="row section-header-height">
                <div class="col-lg-12 align-self-center" data-aos="fade-in">
                    <h2>{{ $t('texto125') }}</h2>
                    <p>
                        {{ $t('texto126') }}
                    </p>
                </div>
                <!--<div class="col-lg-6 col-xl-5 offset-xl-1 align-self-center">
                    <img id="gif" :src="require('@/assets/images/soluciones/aseguradoras/cubo1.svg')"> 
                </div>-->
            </div>
        </div>
        <!-- <div id="video_aboutus">
            <video playsinline id="aboutus-video" class="aboutus-video-right" tabindex="0" autoplay loop muted>
                <source :src="require('../video/membresias_1.mp4')" type='video/mp4;codecs="avc1.42E01E, mp4a.40.2"'>
            </video>

        </div> -->
        <!-- <video playsinline id="aboutus-video-mobile" class="aboutus-video-right" tabindex="0" autoplay loop muted>
                <source :src="require('../video/membresias_1_cube.mp4')" type='video/mp4;codecs="avc1.42E01E, mp4a.40.2"'>
            </video> -->
        <Fondo1 />
    </div>
    <div class="position-relative">
        <div class="container">
            <div class="row section-full-height">
            <!--<div class="col-lg-6 align-self-center order-1 order-lg-0 text-center" data-aos="fade-in">
                    <img id="gif" :src="require('@/assets/images/soluciones/aseguradoras/cubo2.svg')"> 
                </div>-->
                <div class="col-lg-12 align-self-center order-0 order-lg-1" data-aos="fade-in">
                    <h3 class="mb-5">
                       {{ $t('texto127') }}
                    </h3>
                    <h5>{{ $t('texto128') }}</h5>
                    <p class="mb-5">
                        {{ $t('texto129') }}
                    </p>
                    <a id="but" class="btn btn-primary mb-3"><router-link to="/soluciones/empresas"> {{ $t('texto130') }}</router-link></a>
                </div>
            </div>
            
        </div>

    </div>

    <!-- <div class="position-relative">
        <div class="container">
            <div class="row section-header-height">
                <div class="col-lg-6 align-self-center" data-aos="fade-in">
                    <h3 class="mb-5">
                        Construyamos juntos un
                        mundo mejor para todos,
                        seamos libres en cada
                        ACTO
                    </h3>
                    <div class="membership-card">
                        <h5>PLANES DE</h5>
                        <h4 class="font-weight-bold">Protección por asistencias y membresías</h4>
                        <p class="m-0">
                            <a href="#"><img :src="require('@/assets/images/membresias/flecha.svg')" width="25" /></a><small class="pl-4">Sitio externo</small>
                        </p>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-5 offset-xl-1 align-self-center">

                </div>
            </div>
        </div>

        <Fondo1 />
    </div> -->

</div>
</template>

<script>
import Fondo1 from "../components/Fondo1.vue"
//import MembresiasSection from "../components/MembresiasSection.vue"

export default {
    name: "Membresias",
    components: {
        Fondo1,

    },
    mounted() {
        
    }
}
</script>

<style>
#but{
    z-index: 2;
}

</style>
