<template>
  <div id="view-bail">
    <div class="position-relative">
        <div class="container">
            <div class="row section-header-height">
                <div class="col-lg-12 align-self-center">
                    <h2>
                        {{ $t('texto131') }}
                    </h2>

                    <a class="btn btn-primary" href="#fianzas">{{ $t('texto132') }}</a>
                </div>
                <div class="col-lg-6 col-xl-5 offset-xl-1 align-self-center">
                    <!-- <img id="gif" :src="require('@/assets/images/fianzas/cubo1.svg')"> -->
                </div>
            </div>
        </div>

    </div>
    <div id="fianzas" class="container">
        <div class="row section-full-height align-content-center">
            <div class="col-lg-6 custom-list mb-5" data-aos="fade-in">
                <div class="d-flex mb-3">
                    <div class="align-self-center">
                        <img :src="require('@/assets/images/fianzas/pipe.svg')" class="pipe" />
                    </div>
                    <div class="text align-self-center pl-3">
                        <span>{{ $t('texto133') }}</span>
                    </div>
                    <div class="icon align-self-center pl-3">
                        <img :src="require('@/assets/images/fianzas/administrativas.svg')" class="img-fluid" />
                    </div>
                </div>

                <p>
                    {{ $t('texto134') }}
                </p>
            </div>

            <div class="col-lg-6 custom-list mb-5" data-aos="fade-in">
                <div class="d-flex mb-3">
                    <div class="align-self-center">
                        <img :src="require('@/assets/images/fianzas/pipe.svg')" class="pipe" />
                    </div>
                    <div class="text align-self-center pl-3">
                        <span>{{ $t('texto135') }}</span>
                    </div>
                    <div class="icon align-self-center pl-3">
                        <img :src="require('@/assets/images/fianzas/credito.svg')" class="img-fluid" />
                    </div>
                </div>

                <p>
                    {{ $t('texto136') }}
                </p>
            </div>

            <div class="col-lg-6 custom-list mb-5" data-aos="fade-in">
                <div class="d-flex mb-3">
                    <div class="align-self-center">
                        <img :src="require('@/assets/images/fianzas/pipe.svg')" class="pipe" />
                    </div>
                    <div class="text align-self-center pl-3">
                        <span>{{ $t('texto137') }}</span>
                    </div>
                    <div class="icon align-self-center pl-3">
                        <img :src="require('@/assets/images/fianzas/fidelidad.svg')" class="img-fluid" />
                    </div>
                </div>

                <p>
                    {{ $t('texto138') }}
                </p>
            </div>

            <div class="col-lg-6 custom-list mb-5" data-aos="fade-in">
                <div class="d-flex mb-3">
                    <div class="align-self-center">
                        <img :src="require('@/assets/images/fianzas/pipe.svg')" class="pipe" />
                    </div>
                    <div class="text align-self-center pl-3">
                        <span>{{ $t('texto139') }}</span>
                    </div>
                    <div class="icon align-self-center pl-3">
                        <img :src="require('@/assets/images/fianzas/judiciales.svg')" class="img-fluid" />
                    </div>
                </div>

                <p>
                    {{ $t('texto140') }}
                </p>
            </div>

            <div class="col-lg-6 custom-list mb-5" data-aos="fade-in">
                <div class="d-flex mb-3">
                    <div class="align-self-center">
                        <img :src="require('@/assets/images/fianzas/pipe.svg')" class="pipe" />
                    </div>
                    <div class="text align-self-center pl-3">
                        <span>{{ $t('texto141') }}</span>
                    </div>
                    <div class="icon align-self-center pl-3">
                        <img :src="require('@/assets/images/fianzas/otros.svg')" class="img-fluid" />
                    </div>
                </div>

                <p>
                    {{ $t('texto142') }}
                </p>
            </div>
        </div>
    </div>

<div class="container-fluid" style="background-color: #eee;">
    <div class="container">
        <div class="row section-full-height">
            <div class="col-lg-6 align-self-center">
                <h2 style="color: #00264D;">
                    {{ $t('texto143') }}
                </h2>
            </div>
            
            <div class="col-lg-6 col-xl-5 offset-xl-1 align-self-center">
                <FianzasForm/>
                <!-- <form action="/fianzas" data-ajax="true" data-ajax-begin="onBegin" data-ajax-method="POST" data-ajax-success="onSuccess" id="form0" method="post" novalidate="novalidate">                    <div class="form-group">
                    <select class="form-control form-dark" id="Service" name="Service">
                        <option value="">Fianza de tu interés</option>
                        <option value="administrativas">Administrativas</option>
                        <option value="fidelidad">Fidelidad</option>
                    </select>
                    </div>
                    <div class="form-group">
                        <input class="form-control form-dark" data-val="true" data-val-required="El nombre es requerido" id="Name" name="Name" placeholder="Nombre" type="text" value="">
                    </div>
                    <div class="form-group">
                        <input class="form-control form-dark" data-val="true" data-val-required="El teléfono es requerido" id="Phone" name="Phone" placeholder="Teléfono" type="text" value="">
                    </div>
                    <div class="form-group">
                        <input class="form-control form-dark" data-val="true" data-val-required="El correo es requerido" id="Email" name="Email" placeholder="Correo" type="text" value="">
                    </div>
                    <button type="submit" class="btn btn-primary">Pedir información</button>
                    <br>
                    <br>
                </form>             -->
            </div>
        </div>
        </div>
    </div>
</div>


</template>

<script>
import FianzasForm from "../components/FianzasForm.vue"
export default {
    name: 'Fianzas',
    components: {
        FianzasForm
        
    }

}
</script>

<style type="text/css">
							#cubo1 .particle0{fill:#007EFF;}
							#cubo1 .particle1{opacity:0.3;fill:#007EFF;}
							#cubo1 .particle2{opacity:0.2;fill:#007EFF;}
</style>