<template>
<div id='crmWebToEntityForm' class=''>
    <form action='https://crm.zoho.com/crm/WebToLeadForm' name=WebToLeads4884464000000638002 method='POST' onSubmit='javascript:document.charset="UTF-8"; return checkMandatory4884464000000638002()' accept-charset='UTF-8'>
        <input type='text' style='display:none;' name='xnQsjsdp' value='f57783070e81ed6e4ea249e35b350a674e42fb52c6b28a728ede2aa611ba2708'>
        <input type='hidden' name='zc_gad' id='zc_gad' value=''>
        <input type='text' style='display:none;' name='xmIwtLD' value='9dba7424741daec54a874f1ce6e26597baed0290cfd9604497298efa0bf2b81e'>
        <input type='text' style='display:none;' name='actionType' value='TGVhZHM='>
        <input type='text' style='display:none;' name='returnURL' value='https&#x3a;&#x2f;&#x2f;grupoacto.com&#x2f;fianzas&#x23;fianzas'>

        <div class='form-group'>
            <select class='form-control form-dark' type='text' id='Twitter' name='Twitter'>
                <option value="" selected disabled hidden>Fianza de tu interes</option>
                    <option value="administrativas">Administrativas</option>
                    <option value="fidelidad">Fidelidad</option>
            </select>
        </div>
        <div class='form-group'>
            <input class='form-control form-dark' type='text' id='Last_Name' name='Last Name' placeholder="Nombre" maxlength='80'>
        </div>
        <div class='form-group'>
            <input class='form-control form-dark' placeholder="Telefono" type='text' id='Phone' name='Phone' maxlength='30'>
        </div>
        <div class='zcwf_row'>
            <input class='form-control form-dark' type='text' placeholder="Correo" ftype='email' id='Email' name='Email' maxlength='100'>
        </div>
        <div class='zcwf_row'>
            <div class='zcwf_privacy'>
                <div class='dIB vat' align='left'>
                    <br>
                    <div class='displayPurpose  f13'><label class='newCustomchkbox-md dIB w100per '><input autocomplete='off' id='privacyTool4884464000000638002' type='checkbox' name='privacyTool' onclick='disableErr4884464000000638002()'></label></div>
                </div>
                <div class='dIB zcwf_privacy_txt' style="color: #00264D;">Permito que ACTO utilice los datos ingresados en este formulario electrónico para contactarme</div>
                <div id='privacyErr4884464000000638002' style='font-size:12px;color:red;padding-left: 5px;visibility:hidden;'>Please accept this</div>
            </div>
        </div>
        <div class='form-goup'>
            <input type='submit' id='formsubmit' class='formsubmit btn btn-primary' value='Pedir información' title='Pedir información'>
        </div>
    </form>
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>
