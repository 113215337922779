import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap'
import "@/assets/app.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Vuex from 'vuex';
import 'es6-promise/auto';
import LottieAnimation from 'lottie-vuejs';
import VueParticles from 'vue-particles'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import i18n from '@/plugins/i18n';
//import i18n from "./i18n";
import FlagIcon from 'vue-flag-icon';
import VueMeta from 'vue-meta'
import VueMarqueeSlider from 'vue-marquee-slider';

Vue.use(VueMeta)
Vue.use(FlagIcon);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueParticles); // import lottie-vuejs
Vue.use(VueMarqueeSlider)


import Fondo1Particles from './components/Fondo1Particles.vue'


Vue.use(LottieAnimation); // add lottie-animation to your global scope
Vue.use(Vuex);
Vue.config.productionTip = false



router.beforeEach((to, from, next) => {
    let language = to.params.lang;
    if (!language) {
        language = 'es'
    }
    //i18n.locale = window.navigator.language.split('-')[0];
    console.log(language);
    i18n.locale = language
    next()
})

new Vue({
    el: '#app',
    components: { Fondo1Particles },

    created() {
        AOS.init();
    },

    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')