<template>           
<div class="container">
            <div class="row section-header-height">
                <div class="col-lg-6 align-self-center">
                    <h2>
                        Datos enviados con éxito. <br>En breve uno de nuestros asesores se contactará contigo.
                    </h2>

                    <a class="btn btn-primary"><router-link to="/">Regresar a Home</router-link></a>
                </div>
                <div class="col-lg-6 col-xl-5 offset-xl-1 align-self-center">
                    <img id="img" :src="require('@/assets/images/check-mark.svg')">
                </div>
            </div>
        </div>
</template>

<script>
export default {

}
</script>

<style>
#img{
    width: 80%;
    height: auto;
}

</style>