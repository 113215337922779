<template>
  <div class="position-relative">
    <div class="container">
      <div class="row section-header-height">
        <div class="col-lg-6 align-self-center no-show" data-aos="fade-in" data-aos-delay="200" >
          <h2 >
            {{ $t('texto144') }}
          </h2>
        </div>
        <div class="col-lg-6 col-xl-5 align-self-center offset-xl-1 aos-init aos-animate bg-gray" data-aos="fade-in" data-aos-delay="200">
          <div id='crmWebToEntityForm' class='zcwf_lblLeft crmWebToEntityForm' >
            <meta name='viewport' content='width=device-width, initial-scale=1.0'>
            <meta HTTP-EQUIV ='content-type' CONTENT='text/html;charset=UTF-8'>
            <form action='https://crm.zoho.com/crm/WebToLeadForm' name=WebToLeads4884464000001299050 method='POST' v-on:submit.prevent='checkMandatory4884464000001299050()' accept-charset='UTF-8'>
              <input type='text' style='display:none;' name='xnQsjsdp' value='f57783070e81ed6e4ea249e35b350a674e42fb52c6b28a728ede2aa611ba2708' />
              <input type='hidden' name='zc_gad' id='zc_gad' value='' />
              <input type='text' style='display:none;' name='xmIwtLD' value='9dba7424741daec54a874f1ce6e265979acc9d2b0fc96f095ca94fc3f78daba1' />
              <input type='text'  style='display:none;' name='actionType' value='TGVhZHM=' />
              <input type='text' style='display:none;' name='returnURL' value='https://grupoacto.com/success' />

              <div class="form-group text-left">
                Ingresa tus datos
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-7 d-flex">
                      <div :class="['no-valid', error.name]" >*</div>
                      <input 
                        type='text'
                        placeholder="Nombre:"
                        id='Last_Name'
                        name='Last Name'
                        maxlength='80'
                        :class="['form-control', validate('name')]"
                        v-model="form.name"
                      />
                  </div>
                  <div class="col-5">
                    <input type="text" class="form-control" placeholder="Puesto:" v-model="form.position">
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-7 d-flex">
                    <div :class="['no-valid', error.email]" >*</div>
                    <input 
                      type='text'
                      id='Email' 
                      name='Email' 
                      maxlength='100' 
                      :class="['form-control', validate('email')]"
                      placeholder="Correo empresa:" 
                      value=""
                      v-model="form.email"
                    >
                  </div>
                  <div class="col-5 d-flex">
                    <div :class="['no-valid', error.cel]" >*</div>
                    <input type='text' id='Mobile' name='Mobile' maxlength='10' 
                      placeholder="Tel:"
                      value=""
                      v-model="form.cel"
                      :class="['form-control', validate('cel')]"
                      @keypress="isNumber($event)" 
                      />
                  </div>
                </div>
              </div>
              <div class="form-group d-flex">
                <div :class="['no-valid', error.company]" >*</div>
                  <input :class="['form-control',validate('company')]" v-model="form.company" type='text' id='Company' name='Company' maxlength='200' placeholder="Nombre Empresa:" value="">
              </div>
              <div class="form-group d-flex">
                  <select 
                    v-model="form.qtyWorkers" 
                    :class="['form-control custom-select']"
                    id='No_of_Employees' name='No of Employees'>
                    <option selected value="" >Numero de trabajadores</option>
                    <option value="1-50">1-50</option>
                    <option value="51-100">51-100</option>
                    <option value="101-300">101-300</option>
                    <option value="+500">+ 500</option>
                  </select>
              </div>
              <div class="form-group d-flex">
                  <select 
                    v-model="form.qtyClients"
                    :class="['form-control custom-select']"
                    id='Street' name='Street'>
                    <option selected value="" >Numero de clientes</option>
                    <option value="1-50">1-50</option>
                    <option value="51-100">51-100</option>
                    <option value="101-300">101-300</option>
                    <option value="+500">+ 500</option>
                  </select>
              </div>
              <div  :class="['pb-3 pt-1 invalid-feedback font-weight-bold', error.general]" >* Campos obligatorios</div>
              <input type='hidden' id='Phone' name='Phone' maxlength='30' />
              <button type="submit" id='formsubmit' class='btn btn-primary mb-3 formsubmit zcwf_button' value='Agendar DEMO'>Agendar DEMO</button>
              <script type="application/javascript" id='wf_anal' src='https://crm.zohopublic.com/crm/WebFormAnalyticsServeServlet?rid=9dba7424741daec54a874f1ce6e265979acc9d2b0fc96f095ca94fc3f78daba1gidf57783070e81ed6e4ea249e35b350a674e42fb52c6b28a728ede2aa611ba2708gid885e3c1045bd9bdcc91bdf30f82b5696gid14f4ec16431e0686150daa43f3210513'></script>
            </form>
          </div>
        </div>
      </div>
    </div>
    <Fondo1/>
  </div>
</template>

<script>
import Fondo1 from "../components/Fondo1.vue"
export default {
    data () {
      return {
        form: {
          name: "",
          position: "",
          email: "",
          cel: null,
          company: "",
          qtyWorkers: "",
          qtyClients: ""
        },
        error: {
          name: "d-none",
          position: "d-none",
          email: "d-none",
          cel: "d-none",
          company: "d-none",
          qtyWorkers: "",
          qtyClients: "",
          general: ""
        },
        regNames: /^[a-zA-Z]+(\s{1}[a-zA-Z]+)*$/,
        reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/ // eslint-disable-line
      }
    },
    name: "Demo",
    components: {
        Fondo1
    },
    methods: {
      checkMandatory4884464000001299050: function () {
        if (this.verifyInputs()) {
          document.forms['WebToLeads4884464000001299050'].submit()
        }
      },
      validate(type) {
        switch (type) {
          case "name":
            if (this.form.name) {
              this.error.name = "d-none"
              return this.regNames.test(this.form.name)? 'is-valid': 'is-invalid' 
            }
            break;
          case "email":
            if (this.form.email) {
              this.error.email = "d-none"
              return this.reg.test(this.form.email)? 'is-valid': 'is-invalid' 
            }
            break;
          case "cel":
            if (this.form.cel) {
              this.error.cel = "d-none"
              return this.form.cel.length === 10? 'is-valid': 'is-invalid' 
            }
            break;
          case "company":
            if (this.form.company) {
              this.error.company = "d-none"
            }
            break;
        }
      },
      verifyInputs() {
        let error = this.error
        let verify = true
        Object.entries(this.form).map(function (v) {
          if (v[1] === '' || v[1] === null ) {
            error[v[0]] = ""
            verify = false
            error.general = "d-block"
          } else {
            error[v[0]] = "d-none"
            error.general = "d-none"
          }
        })
        return verify
      },
      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault();
        } else {
          return true;
        }
      }
    }
}
</script>

<style>
div.bg-gray input.form-control {
  border-radius: 5px;
  border: none;
  background-color: rgba(220, 220, 220, 0.5);
}

div.bg-gray select.form-control {
  border-radius: 5px;
  border: none;
  background-color: rgba(220, 220, 220, 0.5);
}

div.bg-gray select.form-control option {
  color: gray;
}

div.bg-gray button.btn-primary {
  border-radius: 1rem;
  border: none;
  color: green;
  padding: 0.5rem 3rem;
  background-color: white;
}

div.no-valid {
  color: red;
  width: 1.125rem;
  font-weight: 700;
  font-size: 1.25rem;
  margin-left: -17px;
}

@media only screen and (max-width: 376px){
  .row.section-header-height {
    padding-top: 64px;
    min-height: 93vh;
  }
}
</style>