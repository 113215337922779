import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        contactos: [],
        info: {
            id: '',
            name: '',
            last_name: '',
            email: '',
            phone: '',
            product: '',
            message: '',
            accept: ''
        },
        contactosAPI: [],
        infoAPI: {
            id: '',
            name: '',
            last_name: '',
            email: '',
            phone: '',
            product: '',
            message: '',
            accept: ''
        }
    },
    mutations: {
        set(state, payload) {
            state.contactos.push(payload)
            console.log(state.contactos)
        }
    },
    actions: {
        async setContactoInfo({ commit }, info) {
            try {
                const res = await fetch('https://acto-blue-backend-default-rtdb.firebaseio.com/contactInfo.json', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(info),
                });
                const data = await res.json();
                console.log(data);


            } catch (error) {
                console.log(error);
            }
            commit('set', info);
        },

        async setContactoAPI({ commit }, infoAPI) {
            try {
                const res = await fetch('https://api.saltodigital.mx/formulario/generico.json', {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(infoAPI),
                });
                const data = await res.json();
                console.log(data);


            } catch (error) {
                console.log(error);
            }
            commit('set', infoAPI);
        },

        async verificar() {
            try {
                const r = await fetch('https://acto-blue-backend-default-rtdb.firebaseio.com/contactInfo.json');
                const data_ver = await r.json();
                console.log(data_ver);

            } catch (error) {
                console.log(error);
            }


        }

    },
    modules: {

    }
});